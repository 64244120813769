import { getRoute } from "~/lib/routes";

export default defineNuxtRouteMiddleware(async (to) => {
	const contextToken = useCookie("sw-context-token");
	const { userFromContext, isReady } = process.server
		? useShopwareSessionImplementation(contextToken.value as string | undefined)
		: useShopwareSession();
	await until(isReady).toBeTruthy();

	const isLoggedIn = computed(() => {
		return !!userFromContext.value?.id && !!userFromContext.value?.active;
	});

	if (isLoggedIn.value) {
		if (to.fullPath.includes("/bestellen")) {
			return navigateTo(getRoute("checkoutDeliveryMethod"));
		}

		return navigateTo(getRoute("home"));
	}
});
